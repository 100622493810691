import React, { useState } from 'react'
import './styles.css'
import nhlLeaders from '../../nhlLeaders.json'
import NhlLeadersCard from './nhlLeadersCard';

const NhlLeaders = () => {
    const [currentCategory, setCurrentCategory] = useState('goals');

    const categories = ['goals', 'points', 'GAA', 'wins']; 

    return (
        <div className='NhlLeadersWrapper'>
            <div className='NhlLeadersText'>
                <h4 className='headerTitle'>NHL Leaders 2023/2024</h4>
                <div className='categoryNav'>
                    {categories.map((category) => (
                        <button
                            key={category}
                            onClick={() => setCurrentCategory(category)}
                            className={`categoryButton ${currentCategory === category ? 'active' : ''}`}
                        >
                            {category.toUpperCase()}
                        </button>
                    ))}
                </div>
            </div>
            <NhlLeadersCard title={currentCategory} data={nhlLeaders[currentCategory]} />
        </div>
    );
};

export default NhlLeaders;
