import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import { login, getCusotmers } from '../../../api';
import Cookies from 'universal-cookie';
import LoginModal from '../../loginmodal';
import backgrnd from '../../../assets/images/SignIn.webp';

export default function SignIn() {
    const [errorMessage, setErrorMessage] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [users, setUsers] = useState([]);
    const cookie = new Cookies();

    const getUsers = () => {
        getCusotmers().then(response => {
            setUsers(response.data);
        });
    };

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        // Add styles to the body and html to ensure no scrollbars
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
        };
    }, []);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: () => {
            const username = formik.values.username;
            const password = formik.values.password;
            const func = login(username, password);
            func.then(async (response) => {
                if (response.success) {
                    cookie.set('token', response.token, { path: '/' });
                    cookie.set('firstname', response.data.firstName, { path: '/' });
                    cookie.set('username', response.data.username, { path: '/' });
                    cookie.set('id', response.data._id, { path: '/' });
                    cookie.set('balance', response.data.balance, { path: '/' });
                    cookie.set('email', response.data.email, { path: '/' });
                    cookie.set('currency', response.data.currency, { path: '/' });
                    cookie.set('wallet', response.data.wallet, { path: '/' });
                    window.location.reload();
                } else {
                    setErrorMessage(response.message);
                }
            });
        }
    });

    return (
        <div style={{
            backgroundImage: `url(${backgrnd})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden'
        }}>
            <Container style={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                padding: '20px',
                borderRadius: '10px',
                width: '80%',
                maxWidth: '600px'
            }}>
                {errorMessage && (
                    <p style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>
                        {errorMessage}
                    </p>
                )}
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <h4 className='signInTitle'>Sign In</h4>
                    <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                        <Form.Control
                            style={{ marginBottom: '10px' }}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.username}
                            type="text"
                            name="username"
                            placeholder="Username"
                        />
                        <Form.Control
                            style={{ marginBottom: '10px' }}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            type="password"
                            name="password"
                            placeholder="Password"
                        />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button type="submit" className='ModalFooterSubmit'>
                                Sign In
                            </Button>
                        </div>
                    </Form>
                    <LoginModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                </div>
            </Container>
        </div>
    );
}
