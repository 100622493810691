import React from 'react'
import './styles.css'
import introductionImage from '../../assets/images/introductionImage.webp'

const IntroductionImage = () => {
    return (
        <div className='introductionImageWrapper'>
            <img src={introductionImage} className='introductionImage' />
        </div>
    )
}

export default IntroductionImage