import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import * as Yup from 'yup';
import { register } from '../../../api';
import ContactModal from '../../contactmodal';
import backgrnd from '../../../assets/images/SignIn.webp';

export default function SignUp({ SupportModalShow, setSupportModalShow, Close }) {
    const [startDate, setStartDate] = useState(new Date());
    const [errorMessage, setErrorMessage] = useState('');
    const [phoneValue, setPhoneValue] = useState('');

    const validationSchema = Yup.object({
        firstname: Yup.string('First name').required('First name is required'),
        lastname: Yup.string('Last name').required('Last name is required'),
        username: Yup.string('Username').required('Username is required'),
        password: Yup.string('Password').required('Password is required'),
    });

    const formik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            username: '',
            password: '',
            birthdate: startDate,
        },
        validationSchema: validationSchema,
        onSubmit: () => {
            const data = {
                firstname: formik.values.firstname,
                lastname: formik.values.lastname,
                email: formik.values.email,
                phone: phoneValue,
                username: formik.values.username,
                password: formik.values.password,
                birthdate: startDate,
            };

            const func = register(data);

            func.then(async (response) => {
                if (response.success) {
                    setErrorMessage(response.data.message);
                } else {
                    setErrorMessage('User already taken');
                }
            });
        },
    });

    useEffect(() => {
        // Add styles to the body and html to ensure no scrollbars
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
        };
    }, []);

    return (
        <div style={{
            backgroundImage: `url(${backgrnd})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden'
        }}>
            <Container style={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                padding: '20px',
                borderRadius: '10px',
                width: '80%',
                maxWidth: '600px'
            }}>
                {SupportModalShow ? null :
                    errorMessage ? (
                        <div style={{
                            padding: '16px',
                            backgroundColor: '#FFECCC',
                            marginBottom: '32px',
                            marginTop: '24px',
                            borderRadius: '0',
                            textAlign: 'center'
                        }}>
                            <p style={{ color: 'red' }}>{errorMessage}</p>
                            <Button
                                onClick={() => setSupportModalShow(true)}
                                style={{
                                    borderRadius: '0px',
                                    backgroundColor: '#231123',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    border: 'none'
                                }}
                            >
                                Contact Us
                            </Button>
                        </div>
                    ) : null
                }

                {SupportModalShow ? (
                    <ContactModal Close={Close} setSupportModalShow={setSupportModalShow} />
                ) : (
                    <Row style={{ width: '100%' }}>
                        <Col md={12} style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <h4 className='signInTitle'>Sign Up</h4>
                                <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                                    <div style={{ display: 'flex', gap: '8px', marginBottom: '16px' }}>
                                        <Form.Control
                                            className='textInput'
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.firstname}
                                            type="text"
                                            name="firstname"
                                            placeholder="First name"
                                            style={{ width: '100%' }}
                                        />
                                        <Form.Control
                                            className='textInput'
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.lastname}
                                            type="text"
                                            name="lastname"
                                            placeholder="Last name"
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', gap: '8px', marginBottom: '16px' }}>
                                        <Form.Control
                                            className='textInput'
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            style={{ width: '100%' }}
                                        />
                                        <PhoneInput
                                            className='phoneNumber'
                                            value={phoneValue}
                                            onChange={setPhoneValue}
                                            name="phone"
                                            placeholder="Phone number"
                                            style={{ width: '100%', height: '40px' }}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', gap: '8px', marginBottom: '16px' }}>
                                        <Form.Control
                                            className='textInput'
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.username}
                                            type="text"
                                            name="username"
                                            placeholder="Username"
                                            style={{ width: '100%' }}
                                        />
                                        <Form.Control
                                            className='textInput'
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                            type="password"
                                            name="password"
                                            placeholder="Password"
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                    <div className='mb-4' style={{ textAlign: 'left' }}>
                                        <Form.Group controlId="formBasicBirthDate">
                                            <Form.Label>Date Of Birth:</Form.Label>
                                            <DatePicker
                                                className='date'
                                                selected={startDate}
                                                name='birthdate'
                                                onChange={(date) => setStartDate(date)}
                                                value={startDate}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <Button type="submit" className='ModalFooterSubmit'>
                                        Sign Up
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </div>
    );
}
