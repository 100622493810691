import React, { useEffect, useState } from 'react';
import './styles.css';
import Cookies from 'universal-cookie';
import moment from 'moment/moment';
import { getHistories } from '../../../../api';
import { Col, Row, Table } from 'react-bootstrap';

function Histories() {
    const cookie = new Cookies();
    const user = cookie.get('email');
    const [histories, setHistories] = useState([]);

    useEffect(() => {
        getHistories(user).then(async res => {
            const filterHistories = [];
            res.data.forEach(element => {
                if (element?.user === user) {
                    filterHistories.push(element);
                }
            });

            setHistories(filterHistories);
        }).catch(error => console.log(error));
    }, [user]);

    return (
        <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: 0 }}>
                <div className='titleBackgroundH'>
                    <h2>History</h2>
                </div>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginTop: '16px' }}>
                <div className='historiesWrapper'>
                    <div className='historiesContainer'>
                        <Table responsive="sm">
                            <thead className='historyTableHead'>
                                <tr>
                                    <th><b>Date</b></th>
                                    <th><b>Game</b></th>
                                    <th><b>Team 1 Odds</b></th>
                                    <th><b>Team 2 Odds</b></th>
                                    <th><b>Bet Placed</b></th>
                                    <th><b>Winnings</b></th>
                                    <th><b>Losses</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    histories?.map(item => (
                                        <tr key={item?.user}>
                                            <td align='left'><p>{moment(item?.date).format('L')}</p></td>
                                            <td align='left'><p>{item?.team1} vs {item?.team2}</p></td>
                                            <td align='left'><p>{item?.team1odds}</p></td>
                                            <td align='left'><p>{item?.team2odds}</p></td>
                                            <td align='left'><p>{item?.betPlaced + " " + item?.currency}</p></td>
                                            <td align='left'><p>{item?.winings + " " + item?.currency}</p></td>
                                            <td align='left'><p>{item?.losses + " " + item?.currency}</p></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Col>
            {/* <Col xxl={4} xl={4} lg={4} className='historyImageContainer'>
                <img src={history} style={{ width: '100%', height: '100%' }} />
            </Col> */}
        </Row>
    );
}

export default Histories;
