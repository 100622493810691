import React from 'react';
import './styles.css'; // Import the styles
import { FaUserNinja } from "react-icons/fa6";

const Reviews = () => {
    const reviews = [
        {
            id: 1,
            author: "Jane Doe",
            rating: 5,
            text: "This product is amazing! I've seen great results after just one month.",
            date: "2023-01-15"
        },
        {
            id: 2,
            author: "John Smith",
            rating: 4,
            text: "Very satisfied with my purchase. Will definitely recommend to my friends.",
            date: "2023-01-20"
        },
        {
            id: 3,
            author: "Alice Johnson",
            rating: 5,
            text: "Fantastic quality and excellent customer service. Highly recommend!",
            date: "2023-02-09"
        },
        {
            id: 4,
            author: "Michael Brown",
            rating: 3,
            text: "The product is good, but the shipping was slower than expected.",
            date: "2023-02-15"
        },
        {
            id: 5,
            author: "Emily Davis",
            rating: 4,
            text: "Really good experience overall. Will be shopping here again.",
            date: "2023-03-03"
        },
        {
            id: 6,
            author: "Chris Wilson",
            rating: 5,
            text: "Exceptional quality! Surpassed my expectations.",
            date: "2023-03-10"
        },
        {
            id: 7,
            author: "Rebecca Moore",
            rating: 4,
            text: "Good value for the price. Happy with the purchase.",
            date: "2023-03-15"
        },
        {
            id: 8,
            author: "James Taylor",
            rating: 3,
            text: "Decent product, but I have some reservations about it.",
            date: "2023-03-22"
        },
        {
            id: 9,
            author: "Laura Martinez",
            rating: 5,
            text: "Love it! I would definitely buy this product again!",
            date: "2023-03-30"
        },
        {
            id: 10,
            author: "Kevin Johnson",
            rating: 4,
            text: "Impressive results, was not expecting this much quality!",
            date: "2023-04-05"
        },
        {
            id: 11,
            author: "Sophia Lee",
            rating: 5,
            text: "Absolutely perfect. Could not be happier with my purchase!",
            date: "2023-04-10"
        },
        {
            id: 12,
            author: "Olivia Gonzalez",
            rating: 2,
            text: "Unfortunately, it did not meet my expectations.",
            date: "2023-04-15"
        },
        {
            id: 13,
            author: "David Smith",
            rating: 3,
            text: "Average product, nothing too exciting.",
            date: "2023-04-20"
        },
        {
            id: 14,
            author: "Isabella Brown",
            rating: 5,
            text: "Excellent! This is a must-have product!",
            date: "2023-04-25"
        },
        {
            id: 15,
            author: "Ethan Williams",
            rating: 4,
            text: "Very good, though there are a few things I'd change.",
            date: "2023-04-30"
        }
    ];

    return (
        <div className="reviews-container">
            <h2 className="reviews-header">Customer Reviews</h2>
            <div className="review-row">
                {reviews.map(review => (
                    <div key={review.id} className="review-col">
                        <div className="review-card">
                            <div className="card-body">
                                <div className="card-header">
                                    <div className="icon-container">
                                        <FaUserNinja size={30} color='#fff' />
                                    </div>
                                    <h5 className="card-title">{review.author}</h5>
                                </div>
                                <p className="card-text">{review.text}</p>
                                <p className="card-date"><small className="text-muted">Posted on: {review.date}</small></p>
                                <p className="card-rating">Rating: <span className="star">{'★'.repeat(review.rating)}</span></p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Reviews;
 