import React from 'react'
import './styles.css'
import nbaDrafts from '../../nbaDrafts.json'
import { cutText } from '../../helpers/getHelpers'

const NbaDrafts = ({ firstRound }) => {
    return (
        <div className='NbaDraftsWrapper'>
            <div className='NbaDraftsText'>
                <h4>NBA Drafts 2023 - {firstRound ? 'First' : 'Second'} Round</h4>
            </div>
            <div className='NbaDraftsContentContainer'>
                {
                    nbaDrafts?.slice(firstRound ? 0 : 30, firstRound ? 30 : nbaDrafts?.length)?.map((draft) => {
                        return (
                            <>
                                {
                                    firstRound ?
                                        <div className='NbaDraftsContent'>
                                            <div>
                                                {
                                                    draft?.Headshot ?
                                                        <img src={`https://global.nba.com${draft?.Headshot}`} width={70} height={70} />
                                                        :
                                                        <img src={`https://cdn.nba.com/logos/nba/${draft?.TeamID}/primary/L/logo.svg`} width={70} height={70} />
                                                }
                                            </div>
                                            <div className='NbaDraftsPickNoContainer'>
                                                <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
                                                    <h6 style={{ margin: 0, fontSize: '14px' }}>#{draft?.PickNo}</h6>
                                                    <p style={{ fontSize: '14px' }}><b>{draft?.TeamABR}</b></p>
                                                </div>
                                                <p style={{ fontSize: '14px' }}>{cutText(draft?.PlayerName, 13, 13)}</p>
                                            </div>
                                        </div>
                                        :
                                        <div className='NbaDraftsContentSecondRound'>
                                            <div className='NbaDraftsContentSecondRoundNumContainer'>
                                                <h6 style={{ margin: 0, fontSize: '14px' }}>#{draft?.PickNo}</h6>
                                            </div>
                                            <div style={{ paddingLeft: "8px" }}>
                                                {
                                                    draft?.Headshot ?
                                                        <img src={`https://global.nba.com${draft?.Headshot}`} width={60} height={60} />
                                                        :
                                                        <img src={`https://cdn.nba.com/logos/nba/${draft?.TeamID}/primary/L/logo.svg`} width={60} height={60} />
                                                }
                                            </div>
                                            <div style={{ paddingLeft: '8px' }}>
                                                <p style={{ fontSize: '14px' }}><b>{draft?.TeamABR}</b></p>
                                                <p style={{ fontSize: '14px' }}>{draft?.PlayerName}</p>
                                            </div>
                                        </div>
                                }
                            </>

                        )
                    })
                }
            </div>

        </div>
    )
}

export default NbaDrafts