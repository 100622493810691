// import { Popover } from 'bootstrap';
import React, { useState } from 'react'
import { Nav, Navbar, Container, Dropdown } from 'react-bootstrap'
import './style.css';
import DropDown from '../dropdown';
import Buttons from '../button';
import Cookies from 'universal-cookie';
import { navLinks } from './navLinks';
import Modale from '../modal';
import allinbookielogo from '../../assets/images/allinbookielogo.png';

export default function Navigation() {
    const cookie = new Cookies();
    const [modalShow, setModalShow] = useState(false);

    return (
        <div className="navContainer">
            <Container>
                <Navbar expand="lg">
                    <Navbar.Brand href="/">
                        <div style={{ color: 'red' }}>
                            <img src={allinbookielogo} width={45} height={45} />
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle className="hamburgerButton" aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="me-auto" ></Nav>
                        {
                            cookie.get('token') ?
                                <>
                                    <Buttons text='History' kind="transparent" history={true} />
                                    <Buttons text='Deposit' kind="transparent" deposite={true} />
                                    <Buttons text='Withdraw' kind="transparent" withdraw={true} />
                                    <Buttons text='Transactions' kind="transparent" transaction={true} />
                                    <Buttons text='Profile' kind="transparent" profile={true} />
                                    <Buttons text='Contact Us' kind="transparent" contact={true} />
                                    <DropDown />
                                </>
                                :
                                <>
                                    {/* {
                                        navLinks?.map(link => (
                                            <Buttons text={link.link} kind="transparent2" onClick={() => setModalShow(true)} />
                                        ))
                                    } */}
                                    <div style={{ marginRight: 16 }}>
                                        <Buttons text='sign in' kind="primary" signin={true} />
                                    </div>
                                    <Buttons text='sign up' kind="transparent" signup={true} />
                                </>

                        }
                    </Navbar.Collapse>
                </Navbar >
            </Container >
            {
                cookie.get('token') ?
                    cookie.get('messageStatus') === '0' ?
                        <Modale
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            contact={true}
                        />
                        :
                        <Modale
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            message={true}
                        />
                    :
                    <Modale
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        signin={true}
                    />

            }
        </div>
    )
}
