import React, { useEffect, useState } from 'react';
import './styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const NhlLeadersCard = ({ title, data }) => {
    const [highlightedLeader, setHighlightedLeader] = useState(data[0]);
    const [percentage, setPercentage] = useState(0);

    const onHoverLeader = (leader) => {
        setHighlightedLeader(leader);
        const maxStat = Math.max(...data.map(d => d.value));
        setPercentage((leader.value / maxStat) * 100);
    };

    const getImageUrl = (imagePath) => {
        return imagePath;
    }

    useEffect(() => {
        setHighlightedLeader(data[0]);
        const maxStat = Math.max(...data.map((d) => d.value));
        setPercentage((data[0].value / maxStat) * 100);
    }, [data]);

    return (
        <>
        <div className='NhlLeadersText'>
            <h4 className='headerTitle'>{`${title}`}</h4>
        </div>
        <div className='nhlLeadersCardContainer'>
            <div className='circularProgressContainer'>
                <CircularProgressbar
                    value={percentage}
                    background
                    backgroundPadding={1}
                    styles={buildStyles({
                        backgroundColor: "#065525",
                        textColor: "#76c77e",
                        pathColor: "#76c77e",
                        trailColor: "transparent",
                        textSize: '0px'
                    })}
                />
                <img 
                    className='nhlLeaderHeadshot' 
                    src={getImageUrl(highlightedLeader?.headshot)} 
                    alt={`${highlightedLeader?.firstName} ${highlightedLeader?.lastName}`}
                />
                <p className='count'>{`${highlightedLeader.value} ${title}`}</p>
            </div>
            <div className='nhlLeadersDetails'>
                <p className='number'>{`${highlightedLeader.sweaterNumber}`}</p>
                <div className='nhlLeaderInfo'>
                    <h2>{`${highlightedLeader.firstName?.default} ${highlightedLeader.lastName?.default}`}</h2>
                    <h2>{`Position ${highlightedLeader.position}`}</h2>
                    <h3>{highlightedLeader.teamAbbrev}</h3>
                    <p>{`${highlightedLeader.value} ${title}`}</p>
                </div>
            </div>
            <div className='nhlLeadersList'>
                {data.map((leader, index) => (
                    <div
                        key={index}
                        className='leaderItem'
                        onMouseEnter={() => onHoverLeader(leader)}
                        onMouseLeave={() => onHoverLeader(data[0])}
                    >
                        {`${leader.firstName?.default} ${leader.lastName?.default}`} - {leader.value}
                    </div>
                ))}
            </div>
        </div>
        </>
    );
};

export default NhlLeadersCard;
