const cutText = (str, len, cut) => {
    if (str.length > len) {
        return str.substring(0, cut) + '...';
    } else {
        return str;
    }
}

const sortArrayAsc = (arr) => {
    arr.sort((a, b) => {
        return a.standings.confRank - b.standings.confRank;
    })
    return arr;
}

export {
    cutText,
    sortArrayAsc
}