import nhl from '../../assets/images/nhl.png'
import mlb from '../../assets/images/mlb.png'
import nba from '../../assets/images/nba.png'
import nfl from '../../assets/images/nfl.png'
import mls from '../../assets/images/mls.png'
import mlr from '../../assets/images/mlr.png'
import nwsl from '../../assets/images/nwsl.png'
import mlu from '../../assets/images/mlu.jpg'
import mlv from '../../assets/images/mlv.jpeg'

export const leagues = [
    {
        icon: nhl,
        leagueName: 'NHL',
        width: 50,
        height: 50,
        games: 10
    },
    {
        icon: mlb,
        leagueName: 'MLB',
        width: 50,
        height: 50,
        games: 12
    },
    {
        icon: nba,
        leagueName: 'NBA',
        width: 50,
        height: 50,
        games: 8
    },
    {
        icon: nfl,
        leagueName: 'NFL',
        width: 40,
        height: 50,
        games: 7
    },
    {
        icon: mls,
        leagueName: 'MLS',
        width: 50,
        height: 50,
        games: 15
    },
    {
        icon: mlr,
        leagueName: 'MLR',
        width: 45,
        height: 50,
        games: 10
    },
    {
        icon: mlv,
        leagueName: 'MLV',
        width: 45,
        height: 50,
        games: 11
    },
    {
        icon: mlu,
        leagueName: 'MLU',
        width: 70,
        height: 50,
        games: 12
    },
    {
        icon: nwsl,
        leagueName: 'NWSL',
        width: 50,
        height: 50,
        games: 14
    },

]