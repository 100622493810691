import React from 'react';
import moment from 'moment';
import { cutText } from '../../helpers/getHelpers';
import './style.css'; // Ensure this file includes the CSS you've provided
import { Spinner } from 'react-bootstrap';

export default function News({ news, loading }) {
  return (
    <>
      <div className='NewsWrapper'>
        {
          loading ?
            <div style={{ height: 450, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Spinner animation="grow" style={{ backgroundColor: "#C6771F" }} />
            </div>
            :
            news?.map((item, index) => (
              <div className="card" key={index}>
                <div className="content">
                  <div className="back">
                    <div className="back-content">
                      <img className="bg-image" src={item?.enclosures[0].url} alt='image'/>
                        <div className="newsNumb">
                          <h2>News</h2>
                          <h3>#{index + 1}</h3>
                        </div>
                    </div>
                  </div>
                  <div className="front">
                    <div className="img">
                        <img src={item?.enclosures[0].url} alt='image'/>
                    </div>
                    <div className="front-content">
                      <small className="badge">{item.category}</small>
                      <div className="description">
                        <div className="title">
                          <p><strong>{cutText(item.title, 0, 50)}</strong></p>
                        </div>
                            <p>{cutText(item?.description, 50, 220)}</p>
                      </div>
                      <p className="card-footer">
                          Published: {moment(item.published).format('YYYY-MM-DD')}
                        </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
        }
      </div>
    </>
  );
}
