import React from 'react';
import './styles.css';
import { Container, Spinner } from 'react-bootstrap';
import YouTubeEmbed from '../youtubeEmbed';

const Highlights = ({ highlights, loading }) => {
    return (
        <Container className='highlights-wrapper'>
            <div className='highlights-title-container'>
                <h2>Sports Highlights</h2>
            </div>
            <div>
                {loading ? (
                    <div className='loading-spinner'>
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <div className='highlights-content-container'>
                        {highlights?.map((highlight) => (
                            <div className='highlights-content' key={highlight.id}>
                                <YouTubeEmbed youtubeUrl={highlight?.strVideo} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Container>
    );
}

export default Highlights;
